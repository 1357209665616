.whatsapp-call{
    transition: all 0.5s;
    position: fixed;
    bottom: 64px;
    left: 20px;
    width: 40px;
    height: 40px;
    z-index: 9;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
    padding: 7px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
    }
}