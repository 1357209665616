.footerSection {
    background: #F5F5F5;
    padding: 46px 0px 15px;

    .footermenu {
        li {
            display: block;

            h3 {
                text-transform: uppercase;
                color: #131313;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.05em;
                margin: 0 0 20px;
                display: block;
                font-family: "pangrammedium";
            }

            .footer-head {
                text-transform: uppercase;
                color: #131313;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.05em;
                margin: 0 0 20px;
                display: block;
                font-family: "pangrammedium";
            }

            .footermenuLink {
                  font-size: 0.875rem;
                line-height: 22px;
                font-family: "pangramregular";
                color: #333333;
                letter-spacing: 0.02em;
                display: block;
                font-weight: normal;
                transition: 0.3s all ease;
                padding: 5px 0px;

                &:hover {
                    color: #e57200;
                }
            }
        }
    }

    .footersocial {
        padding: 25px 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
            margin: 0px 7px;
            display: inline-block;

            &:first-child {
                margin-left: 0px;
            }

            a {
                img {
                    height: auto;
                    width: 27px;
                }

            }
        }
    }

    .footerlocation {
        p {
            font-size: 14px;
            line-height: 22px;
            color: #333333;
            font-weight: normal;
            margin: 0px;
            letter-spacing: 0.02em;

            span {
                   font-size: 0.9375rem;
                line-height: 20px;
                    font-family: "pangrammedium";
            }
        }

    }

    .footerpay {
        p {
            font-size: 14px;
            line-height: 22px;
            font-family: "pangramregular";
            font-weight: normal;
            display: block;
            padding: 0 0 0 5px;
            color: #333333;
            letter-spacing: 0.02em;
        }

        ul {
            li {
                img {
                    height: auto;
                }
            }
        }

        .footerapp {
            display: inline-block;

            img {
                height: auto;
                margin: 5px 10px 0 0px;
                width: 126px;
            }
        }
    }

    .footerTop {
        margin-bottom: 35px;
    }

    .footerMiddle {
        padding: 30px 0;
        border-top: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
    }

    .footerBottom {
        padding: 25px 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footerBottomleft {
            display: flex;
            align-items: center;

            .footerLogo {
                display: inline-block;
                max-width: 190px;
                margin-top: -10px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .footercopyRight {
                padding-left: 25px;

                ul {
                    margin-bottom: 6px;

                    li {
                        display: inline-block;

                        &:first-child {
                            a {
                                padding-left: 0px;
                            }
                        }

                        a {
                            color: #333333;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: normal;
                            display: inline-block;
                            font-family: "pangramregular";
                            border-right: 1px solid #333333;
                            padding: 0 5px 0 5px;
                            transition: 0.3s all ease;

                            &:hover {
                                color: #e57200;
                            }
                        }
                    }
                }

                p {
                    color: #515151;
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: normal;
                    font-family: "pangramregular";
                    margin-bottom: 6px;
                }

            }

        }

        .footerBottomright {
            .footerRegisteredOffice {
                p {
                    line-height: 21px;
                    b {
                        font-family:'pangrammedium';
                        margin: 0 0 5px;
                        color:#222;
                    }
                    span {
                        font-family: "pangramregular";
                        color: #333;
                        line-height: 18px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
// client
.clientsection{
    padding: 20px 0px;
    .clientinner{
    margin: 0px auto;


    }
    ul{
        display: flex;
        overflow: hidden;
        li{
            display: inline-block;
        }
    }
    .clientcardimg{
        height: 60px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-right: 8px;
        img{
            width: 100%;
            height: 45px;
            // filter: grayscale(1);
            transition: all .3s ease;
            object-fit: contain;
        }
        &:hover{
            img{
                filter: revert;
            }
        }

    }

}

@media (max-width:991px) {
    .footerSection {
        padding: 40px 0px 15px;

        .footermenu {
            li {
                h3 {
                    margin: 0 0 15px;
                }
            }
        }

        .footerTop {
            margin-bottom: 30px;
        }

        .footerMiddle {
            padding: 30px 0;
        }

        .footerBottom {
            .footerBottomleft {

                .footerLogo {
                    max-width: 154px;
                }

                .footercopyRight {
                    padding-left: 20px;
                }

            }

            .footerBottomright {
                .footersocial {
                    display: flex;

                    li {
                        margin: 0px 6px;

                        a {
                            img {
                                width: 24px;
                            }

                        }
                    }
                }


            }
        }
    }
}

@media (max-width:767px) {
    .footerSection {
        .footermenu {
            margin-bottom: 20px;

            li {
                h3 {
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0 0 15px;
                }
            }
        }

        .footerpay {
            .footerapp {
                img {
                    width: 115px;
                }
            }
        }

        .footerTop {
            margin-bottom: 20px;
        }

        .footerMiddle {
            padding: 20px 0;

        }

        .footerlocation {
            margin-bottom: 15px;
        }

        .footerpay {
            margin-bottom: 10px;
        }

        .footerBottom {
            padding: 20px 0 30px;
            display: block;

            .footerBottomleft {
                margin-bottom: 20px;
            }

            .footerBottomright {
                .footersocial {
                    li {
                        &:first-child {
                            margin-left: 0px;
                        }
                    }
                }
            }

        }
    }
    .clientsection{
        .clientcardimg{
            height: 50px;
           margin: 0px 12px;
            img{
                height: 26px;
            }
        }
    }
}

@media (max-width:575px) {
    .footerSection {
        .footerBottom {
            .footerBottomleft {
                display: block;

                .footercopyRight {
                    padding-left: 0px;
                    margin-top: 20px;
                }
            }

        }
    }
}