.autocomplete {
  // position: relative;
  display: inline-block;
  width: 90%;
}

.input {
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  outline: none;
  box-shadow: none;
  border-radius: 3px;
  padding: 0px 40px 0px 15px;
  line-height: 1.15;
  font-size: 0.875rem;
  color: #202020;
  font-family: sans-serif;
}

.dropdown {
  position: absolute;
  width: 100%;
  // max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
}

.option {
  padding: 8px;
  cursor: pointer;

  span {
    color: #333;
    text-decoration: none;
    font-size: 12px;
    border-radius: 50px;
    padding: 6px 12px 6px 12px;
    margin: 0px 0px 10px 10px;

    &.select,
    &:hover {
      background: #f5f5f5;
      color: #e57200;
    }

    b {
      font-weight: normal;
      font-family: 'robotomedium';
      color: #000;
    }
  }

  &.mb10 {
    margin-bottom: 10px;
  }
}

.TopSearchesBox {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  border-radius: 5px;
  box-shadow: 0 7px 7px 0.72px rgba(0, 0, 0, 0.1);
  border: 1px solid #f5f5f5;

  .titlesm {
    font-size: 15px;
    padding: 15px 17px 10px;
  }

  li {
    padding-left: 42px;
    position: relative;
    border-bottom: 1px solid #f5f5f5;

    &:hover {
      background: #f5f5f5;
    }

    &:before {
      content: '';
      width: 19px;
      height: 16px;
      top: 12px;
      background-size: 16px;
      position: absolute;
      background: url('/images/header/resent.svg') no-repeat;
      left: 15px;
    }

    a {
      padding: 10px 0px 10px 0px;
      display: block;
      font-size: 0.8125rem;
      color: #4a4a4a;
      position: relative;
    }
  }

  .mostSearches a {
    border: 1px solid #d7d7d7;
    display: inline-block;
    color: #333;
    border-bottom: 1px solid #d7d7d7;
    padding: 6px 12px 6px 12px;
    border-radius: 50px;
    margin: 0px 0px 10px 10px;
    font-size: 12px;
  }

  a:hover {
    background: #f5f5f5;
    color: #e57200;
  }

  .noResults {
    width: 100%;
    padding: 15px 0px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    font-size: 15px;
  }
}