.headerSection {
  padding: 30px 0px 0px;
  background-color: #fff;

  .container1240 {
    max-width: 1240px;
    margin: 0px auto;
    padding: 0px 10px;
  }

  .headerContent {
    display: flex;
    justify-content: space-between;

    .headerLogo {
      display: inline-block;
      width: 230px;
      margin: -15px 0px 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headerCenter {
      width: 100%;

      form {
        display: flex;
        justify-content: flex-end;
      }

      .headerSearch {
        position: relative;
        max-width: 500px;
        width: 100%;
        margin-right: 20px;
        border: 1px solid #ddd;
        border-radius: 3px;

        .formControl {
          border: 1px solid #ddd;
          padding: 6px 40px 6px 15px;
          border-radius: 3px;
          box-shadow: none;
          height: 40px;
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #202020;
        }

        button {
          position: absolute;
          right: 7px;
          top: 10px;
          cursor: pointer;
        }
      }
    }

    .orderList {
      display: flex;
      align-items: center;
      align-items: flex-start;

      li {
        display: inline-block;
        margin: 0px 12px;
        padding-bottom: 20px;

        &:first-child {
          .orderLInk {
            span {
              img {
                width: 28px !important;
                height: 28px !important;
              }
            }
          }
        }

        &:last-child {
          margin: 0px;
          margin-left: 12px;
        }

        .orderLInk {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          cursor: pointer;

          span {
            height: 26px;
            display: block;

            img {
              width: 23px;
              height: 22px;
            }
          }

          p {
            color: #4a4a4a;
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            margin-bottom: 0px;
            white-space: nowrap;
          }
        }
      }

      .profileDropdown {
        position: relative;

        &:hover {
          .profileCard {
            display: block;
          }
        }
      }

      .profileCard {
        background-color: #fff;
        position: absolute;
        z-index: 10;
        width: 230px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        left: -90px;
        top: 55px;
        display: none;
        transition: all 0.3s ease;

        .signinbtn {
          background-color: #333333;
          color: #fff;
          display: inline-block;
          border-radius: 2px;
          text-transform: uppercase;
          padding: 7px 40px;
          font-family: 'robotomedium';
          font-size: 15px;
          line-height: 20px;
          margin: 0;
          text-align: center;
          transform: all 0.3s ease;
          cursor: pointer;

          &:hover {
            background-color: #e57200;
            color: #fff;
          }
        }

        .profileCardtop {
          padding: 20px 15px 0px 30px;
          text-align: center;

          p {
            text-transform: capitalize;
            color: #515151;
            margin: 15px auto;
            display: block;
            font-size: 13px;
            line-height: 16px;
            font-weight: 300;
            cursor: pointer;
            text-align: left;

            a {
              padding: 5px 10px;
              display: inline-block;
              color: #E27A34;
              transition: all 0.3s ease;

              &:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }

        .profileList {
          li {
            display: block;
            margin: 0px;
            padding: 0px;

            .profile-link {
              padding: 12px 15px 12px 30px;
              display: block;
              text-transform: capitalize;
              text-align: left;
              color: #515151;
              font-size: 13px;
              line-height: 18px;
              position: relative;
              font-weight: 300;
              transition: all 0.3s ease;
              margin: 0px;
              cursor: pointer;

              &:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }

        hr {
          border-top: 1px solid #e7e7e7;
          margin: 0px;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  .headermenuContent {
    padding-top: 8px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;

    // .headerMenu {
    //   display: none; // Initially hidden
    //   &.menuOpen {
    //     display: block; // Shown when `menuOpen` is true
    //   }
    // }

    .headerMenu {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      // header menu spacing between two menu items
      > li {
        flex: 1 1 auto;

        .menuLink {
          color: #535353;
          font-size: 0.875rem;
          line-height: normal;
          font-weight: normal;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 100%;
          margin: 0 0;
          padding: 5px 2px 15px 2px;
          transition: all 0.2s ease;
          flex: 1 1 auto;
          font-family: 'robotoregular';

          &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            content: '';
            height: 2px;
            width: calc(100% - 35px);
            background: #e57200;
            transition: all 0.2s ease;
            bottom: 2px;
            display: none;
          }

          &:hover {
            color: #e57200;

            &:after {
              display: block;
            }
          }
        }

        // for first and last item css for aligments start
        &:first-child {
          .menuLink {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:after {
              margin-left: 0;
              width: calc(100% - 15px);
            }
          }
          max-width: 60px;
        }

        &:last-child {
          .menuLink {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &:after {
              margin-right: 0;
              width: calc(100% - 15px);
            }
          }

          max-width: 80px;
        }

        // for first and last item css for aligments start

        &:first-child {
          .menuLink {
            margin-left: 0px;
            padding-left: 5px;
          }
        }

        &:last-child {
          .menuLink {
            margin-right: 0px;
            text-align: right;
          }
        }

        &:hover {
          .subMenu {
            display: block;
          }
        }
      }
    }

    .subMenu {
      display: none;
      background-color: #fff;
      width: 100%;
      z-index: 9999;
      position: absolute;
      border: 1px solid #f1f1f1;
      top: 100%;
      left: 0px;
      right: 0px;
      box-shadow: 0 4px 7.28px 0.72px rgba(0, 0, 0, 0.2);
      margin: 0px auto;
      max-width: 1240px;
      transition: all 0.3s ease;
      margin-top: -3px;

      // &:after {
      //     background-color: #000000bd;
      //     position: absolute;
      //     left: -9999px;
      //     right: -9999px;
      //     top: 0px;
      //     height: 100vh;
      //     content: '';
      //     display: none;
      // }

      // &:hover{
      //     &:after{
      //         display: block;
      //     }
      // }
      .subMenucontent {
        background-color: #fff;
        position: relative;
        z-index: 1;
      }

      .submenuRow {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .subMenucard {
          padding: 20px 15px 15px;
          border-right: 1px solid #eae4e2;
          display: inline-block;
          width: 100%;

          &:nth-child(even) {
            background-color: #f9f9f9;
          }

          &:nth-last-child(2) {
            border-right: 0px;
          }

          &:last-child {
            background-color: transparent;
            border-right: 0px;
          }

          &:first-child {
            border-left: 0px;
          }

          .submenuList {
            &:not(:first-child) {
              margin-top: 20px;
            }

            li {
              display: block;

              .submenuLink {
                padding: 3px 0;
                color: #535353;
                font-size: 13px;
                line-height: 19px;
                font-weight: 300;
                transition: all 0.2s ease;
                display: inline-block;

                p {
                  margin: 0px;
                  color: #000;
                  font-weight: 500;
                }

                &:hover {
                  color: #e57200;
                }
              }
            }
          }

          .submenuImg {
            position: relative;
            display: inline-block;
            width: 100%;
            padding: 0px 5px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            img {
              border-radius: 10px;
              min-width: 295px;
              max-width: 295px;
              max-height: 335px;
              width: 100%;
              height: auto;
            }
          }

          .mtTop {
            margin-top: 20px;
          }
        }
      }
    }

    &:hover .subMenu {
      &:after {
        display: block;
      }
    }

    .CloseMenu {
      position: absolute;
      right: 9px;
      top: 13px;
      display: none;

      img {
        width: 32px;
      }
    }
  }
}

.mt-20 {
  margin-top: 20px !important;
}
@media (max-width: 1280px) {
  .headerSection {
    padding: 30px 40px 0px;
    border-bottom: 1px solid #e5e5e5;
    .headermenuContent {
      border-bottom: 0px;
      .headerMenu {
        li {
          &:first-child {
            .menuLink {
              padding-left: 0px;
            }
          }
        }
      }
      .subMenu {
        width: 93%;
      }
    }
  }
}
@media (max-width: 1100px) {
  .headerSection {
    .headerContent {
      .headerLogo {
        width: 190px;
        margin: -7px 0px 10px;
      }

      .headerCenter {
        .headerSearch {
          max-width: 360px;
          margin-right: 12px;

          .formControl {
            height: 35px;
            font-size: 12px;
            line-height: 18px;
          }

          button {
            top: 7px;
          }
        }
      }

      .orderList {
        li {
          margin: 0px 8px;

          &:first-child {
            .orderLInk {
              span {
                img {
                  width: 26px !important;
                  height: 26px !important;
                }
              }
            }
          }

          .orderLInk {
            span {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .profileCard {
          width: 230px;

          .signinbtn {
            padding: 7px 30px;
            font-size: 14px;
            line-height: 20px;
          }

          .profileCardtop {
            padding: 15px 15px 0px 15px;

            p {
              margin: 12px auto;

              a {
                padding: 5px 10px;
              }
            }
          }

          .profileList {
            li {
              .profile-link {
                padding: 10px 15px 10px 20px;
              }
            }
          }
        }
      }
    }

    .headermenuContent {
      .headerMenu {
        li {
          .menuLink {
            font-size: 13px;
            line-height: 17px;
          }
        }
      }

      .subMenu {
        .submenuRow {
          .subMenucard {
            padding: 15px 15px 15px;

            .submenuImg {
              img {
                min-width: 227px;
                max-width: 227px;
                max-height: 325px;
              }
            }

            .mtTop {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .headerSection {
    .headermenuContent {
      .headerMenu {
        li {
          .menuLink {
            margin: 0px 0;
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .headerSection {
    display: none !important;
    max-height: 80px !important;
  }
}

@media (min-width: 992px) {
  .headerSection {
    z-index: 999;
    position: relative;
  }

  .hovered {
    background-color: #000000bd;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    height: 100vh;
    content: '';
    display: block;
    z-index: 99;
  }

  .notHovered {
    display: none;
  }
}
