.topheaderRight {
  display: flex;
  align-items: center;

  li {
    padding: 0px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 22px;

    &:last-child {
      padding-right: 0px;
    }

    .topheaderLinks {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }

      p {
        color: #4a4a4a;
        font-size: 13px;
        line-height: normal;
        margin: 0;
        transition: 0.3s all ease;
        font-family: 'robotoregular';
      }

      &:hover {
        p {
          color: #e57200;
        }
      }
    }
  }

  li:nth-child(n + 2) {
    border-left: 1px solid #b3b3b3;
    padding-left: 10px;
  }
}
