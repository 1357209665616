.stripsection{
    padding: 30px 0 10px;      
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
    .stripcard{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .stripimg{
            margin-right: 15px;
            display: block;
            img{
                height: 48px;
            }
        }
        .striptext{
            .title{
                font-size: 22px;
                line-height: 35px;
                color: #333;
                font-weight: 500;
                font-family: "pangrammedium";
                letter-spacing: 0.1px;
                margin: 0px 0px 0px;
            }
            h3{
                font-size: 22px;
                line-height: 35px;
                color: #333;
                font-weight: 500;
                font-family: "pangrammedium";
                letter-spacing: 0.1px;
                margin: 0px 0px 0px;
            }
            p{
                font-family: "pangramregular";
                   color: #515151;
                font-size: 14px;
                line-height: 16px;
                margin: 0px;
                font-weight: 400;
            }
        }
    }
}
@media (max-width:767px){
    .stripsection{
        .stripcard{
            .stripimg{
                img{
                    height: 35px;
                }
            }
            .striptext{
                h3{
                    font-size: 18px;
                    line-height: 24px;
                }
               
            }
        }
    } 
}